import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Documentation from '../views/DocumentationView.vue'
import Tools from '../views/ToolsView.vue'
import accordion1 from '../views/Accordion1View.vue'
import button1 from '../views/Button1View.vue'
import button2 from '../views/Button2View.vue'
import slider1 from '../views/Slider1View.vue'
import slider2 from '../views/Slider2View.vue'
import upload1 from '../views/Upload1View.vue'
import pulse from '../views/PulseView.vue'
import preloader1 from '../views/Preloader1View.vue'
import preloader2 from '../views/Preloader2View.vue'
import navbar1 from '../views/Navbar1View.vue'
import navbar2 from '../views/Navbar2View.vue'
import table1 from '../views/Table1View.vue'
import formA1 from '../views/FormA1View.vue'
import formA2 from '../views/FormA2View.vue'
import formB1 from '../views/FormB1View.vue'
import formB2 from '../views/FormB2View.vue'
import formC from '../views/FormC1-2View.vue'
import homepage1 from '../views/Homepage1View.vue'
import progressbar1 from '../views/Progressbar1View.vue'
import progressbar2 from '../views/Progressbar2View.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/components/accordion1',
    name: 'accordion1',
    component: accordion1
  },
  {
    path: '/components/button1',
    name: 'button1',
    component: button1
  },
  {
    path: '/components/button2',
    name: 'button2',
    component: button2
  },
  {
    path: '/components/slider1',
    name: 'slider1',
    component: slider1
  },
  {
    path: '/components/slider2',
    name: 'slider2',
    component: slider2
  },
  {
    path: '/components/upload1',
    name: 'uplaod1',
    component: upload1
  },
  {
    path: '/components/pulse',
    name: 'pulse',
    component: pulse
  },
  {
    path: '/components/preloader1',
    name: 'preloader1',
    component: preloader1
  },
  {
    path: '/components/preloader2',
    name: 'preloader2',
    component: preloader2
  },
  {
    path: '/components/navbar1',
    name: 'navbar1',
    component: navbar1
  },
  {
    path: '/components/navbar2',
    name: 'navbar2',
    component: navbar2
  },
  {
    path: '/components/table1',
    name: 'table1',
    component: table1
  },
  {
    path: '/components/formA1',
    name: 'formA1',
    component: formA1
  },
  {
    path: '/components/formA2',
    name: 'formA2',
    component: formA2
  },
  {
    path: '/components/formB1',
    name: 'formB1',
    component: formB1
  },
  {
    path: '/components/formB2',
    name: 'formB2',
    component: formB2
  },
  {
    path: '/components/formC',
    name: 'formC',
    component: formC
  },
  {
    path: '/components/homepage1',
    name: 'homepage1',
    component: homepage1
  },
  {
    path: '/components/progressbar1',
    name: 'progressbar1',
    component: progressbar1
  },
  {
    path: '/components/progressbar2',
    name: 'progressbar2',
    component: progressbar2
  }

  // {
  //   // path: '/about',
  //   // name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
