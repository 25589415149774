<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Component</p>
          <i class="fa fa-chevron-right"></i>
          <p>Table</p>
        </span>
        <h1>Table1</h1>
        <p>Beautiful Search By filter Table</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>Jeet Saru (youtube)</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
            <div class="pref_div">
              <label><i class="fab fa-css3-alt"></i>Css</label>
              <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
            <div class="pref_div">
              <label><i class="fab fa-js-square"></i>Javascript</label>
              <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Navbar2View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
  <div class="Dashboard">
    <div class="payment">
      <div class="title-form">
        <h3>Transaction Processed</h3>
        <form action="#" autocomplete="off">
          <input type="text" placeholder="filter" id="filter">
        </form>
      </div>
      <table>
        <thead>
          <th>Select</th>
          <th>Client Name</th>
          <th>Order No</th>
          <th>Payment Cost</th>
          <th>Payment Mode</th>
          <th>Date</th>
          <th>Status</th>
        </thead>
        <tbody>      
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face1.jpg" alt=""> Mary Jane</td>
            <td><p>0168</p></td>
            <td><p>$ 5000</p></td>
            <td><p>Pay on Dilevery</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="pending" onclick="deleteone()">Pending</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face9.jpg" alt="">Sarah Abraham</td>
            <td><p>0189</p></td>
            <td><p>$ 4500</p></td>
            <td><p>Credit Card</p></td>
            <td><p>04 Jan 2017</p>  </td>
            <td><button class="processing" onclick="deleteone()">Rejected</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face8.jpg" alt="">Catherine Willson</td>
            <td><p>0169</p></td>
            <td><p>$ 4000</p></td>
            <td><p>Online Payment</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="delivery" onclick="deleteone()">Approved</button></td>
        </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face7.jpg" alt="">Jack Sparrow</td>
            <td><p>0200</p></td>
            <td><p>$ 6000</p></td>
            <td><p>Credit Card</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="pending" onclick="deleteone()">Pending</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face6.jpg" alt="">Jim Jones</td>
            <td><p>1124</p></td>
            <td> <p>$ 2500</p></td>
            <td><p>Pay on Dilevery</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="processing" onclick="deleteone()">Rejected</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face5.jpg" alt="">Mark Brook</td>
            <td><p>0232</p></td>
            <td> <p>$ 5000</p></td>
            <td><p>Online Payment</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="delivery" onclick="deleteone()">Approved</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face4.jpg" alt="">Sliver Brown</td>
            <td><p>4467</p></td>
            <td><p>$ 1800</p></td>
            <td><p>Pay on Dilevery</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="pending" onclick="deleteone()">Pending</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face3.jpg" alt="">Mary kay</td>
            <td><p>0022</p></td>
            <td><p>$ 3300</p></td>
            <td><p>Credit Card</p></td>
            <td><p>04 Jan 2017</p>  </td>
            <td><button class="processing" onclick="deleteone()">Rejected</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face27.jpg" alt="">Slim shady</td>
            <td><p>0443</p></td>
            <td><p>$ 1200</p></td>
            <td><p>Credit Card</p></td>
            <td><p>04 Jan 2017</p>  </td>
            <td><button class="delivery" onclick="deleteone()">Approved</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face25.jpg" alt="">Nick Sandal</td>
            <td><p>0731</p></td>
            <td><p>$ 1500</p</td>
            <td><p>Pay on Dilevery</p></td>
            <td><p>04 Jan 2017</p>  </td>
            <td><button class="pending" onclick="deleteone()">Pending</button></td>
          </tr>
          <tr>
            <td><input type="checkbox" value=""></td>
            <td><img src="./image/faces/face26.jpg" alt="">Sandra Wolf</td>
            <td><p>01331</p></td>
            <td><p>$ 1900</p></td>
            <td><p>Online Payment</p></td>
            <td><p>04 Jan 2017</p></td>
            <td><button class="processing" onclick="deleteone()">Rejected</button></td>
          </tr>
          <tr>
              <td><input type="checkbox" value=""></td>
              <td><img src="./image/faces/face24.jpg" alt="">Billy King</td>
              <td><p>0021</p></td>
              <td><p>$ 2100</p></td>
              <td><p>Pay on Dilevery</p></td>
              <td><p>04 Jan 2017</p>  </td>
              <td><button class="delivery" onclick="deleteone()">Approved</button></td>
          </tr>
        </tbody>
      </table> 
    </div>
  </div> 
</body>
`
    this.$refs.textarea2.value =
`*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: system-ui;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
:root {
    --h_color:#000;
    --p_color:#3f3c3c;
    --box_shadow:rgb(202, 202, 202);

}
::-webkit-scrollbar{
    width: 5px;
    height: 6px;
}
::-webkit-scrollbar-track{
    opacity: 0px;
}
::-webkit-scrollbar-thumb{
    height: 50px;
    background-color:rgba(179,179,179,1);
    border-radius: 10px;
}
::-webkit-scrollbar-corner{
    height: 0px;
}
a{
    color: var(--h_color);
}
h1,h2,h3,h4,h5,h6{
    font-weight: 500;
    color: var(--h_color);
}
p{
    font-weight: 500;
    color: var(--p_color);
}
.fa{
    color: var(--h_color);
}
body{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.Dashboard{
    width: 98%;
    height: 90%;
    overflow: auto;
}
.Dashboard .payment{
    width: 100%;
    min-width: 800px;
    height: 100%;
    padding: 0px 15px 15px 15px;
    background-color:#fff;
    overflow: auto;   
}
.Dashboard .payment .title-form{
    width: 100%;
    height: 12%;
    /* padding-block: 2px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c5c5c5;
    background-color:#fff;   
    position: sticky;
    top: 0px;
}
.Dashboard .payment .title-form form{
    display: flex;
    width: 220px;
    height: 30px;
    border: 1px var(--h_color) solid;
    border-radius: 5px;
    overflow: hidden;
}
.Dashboard .payment .title-form form input[type=text]{
    padding: 10px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color:var(--p_color);
    background-color: transparent;
}
.Dashboard .payment table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: auto;
}
th, td{
    color: var(--p_color);
    font-weight: 600;
    padding: 5px;
    border-bottom: 1px solid #c5c5c5;
}
.Dashboard .payment table thead th{
    font-size: 14px;
    font-weight: 700;
    padding-block: 10px;
    text-transform: uppercase;
}
.Dashboard .payment table tbody tr:hover{
    background-color:var(--grey);
}
.Dashboard .payment tbody tr td:nth-child(2){
    text-align: left;
}
.Dashboard .payment table tr td img{
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: #3f3c3c;
}
.Dashboard .payment table tr td button{
    margin: 0px auto;
    padding: 5px 20px;
    border: none;
    outline: none;
    font-weight: 600;
    border-radius: 5px;
    background-color: transparent;
}
.Dashboard .payment table tr td .delivery{
    color: #00d25b;
    border: 1px #00d25b solid;
}
.Dashboard .payment table tr td .delivery:hover{
    color: #fff;
    background-color:#00d25b;
}
.Dashboard .payment table tr td .processing{
    color: #fc424a;
    border: 1px #fc424a solid;
}
.Dashboard .payment table tr td .processing:hover{
    color: #fff;
    background-color:#fc424a;
}
.Dashboard .payment table tr td .pending{
    color: #ffab00;
    border: 1px #ffab00 solid;
}
.Dashboard .payment table tr td .pending:hover{
    color: #fff;
    background-color:#ffab00;
}

/* event handler of adding hide */
tbody tr{
    --delay:1s;
    transition: 0.2s ease-in-out var(--delay), background-color;
} 
tbody tr.hide{
    transform: translate(100%); 
    transition: 0.5s ease-in-out var(--delay);
}
tbody tr.hide td,
tbody tr.hide td p{
    display: none;
}
@media (max-width:600px) {
}`
    this.$refs.textarea3.value =
`// ============== table fillter ============
const filtered = document.getElementById('filter');
const tableRows = document.querySelectorAll('table tbody tr');

filtered.addEventListener('input', sortingTable);
function sortingTable(){
  // const filterValue = filtered.value.toLowerCase()
  // console.log(filterValue)
  tableRows.forEach((rows, i) =>{
    const filterValue = filtered.value.toLowerCase();
    const eachRow = rows.textContent.toLowerCase()
    // console.log(filterValue)
    // console.log(rows.textContent)
    console.log(eachRow.indexOf(filterValue))
    rows.classList.toggle('hide',eachRow.indexOf(filterValue) < 0);
    rows.style.setProperty('--delay', i / 25 +"s")
  })  
}`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
