<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Doc</p>
          <i class="fa fa-chevron-right"></i>
          <p>Introduction</p>
        </span>
        <h1>Introduction</h1>
        <p class="P">Beautifully designed components that you can copy and paste
          into your apps. Accessible. Customizable.
        </p>
        <p class="P small ">This is not <b>Not</b> a componenet library. Its a collecton
          of sourced and re-useable components(UI) that you can copy and paste into your apps.
        </p>
        <h3 class="P small">What do you meen by not a coomponenet library?</h3>
        <p class="P small">
          I mean you do not install it as a dependecy. It is not available
          or distributed via npm.
        </p>
        <p class="P small">
          Pick the componenet you need copy and paste the code into your project and customize to your
          need.
        </p>
        <h1>FAQ</h1>
        <details>
          <summary>Why copy and paste<i class="fa fa-chevron-down"></i></summary>
          <p>
            This is aimed to make your web design easier more interesting
            and interacting by providing simply easy ready to use components, build
            using Html, css and vanilla javascript to copy and paste in your
            design build.
          </p>
          <p> Every developer is at liberty to change any part of the components to suit their build.</p>
        </details>
        <details>
          <summary>Can i use this in my Project<i class="fa fa-chevron-down"></i></summary>
          <p>Yes. You are free to use for personal and commercial project.</p>
          <p>
            If you have any Ul designed that you what to convert to code
            using Html, css and js Lets Talk on <a href="https://twitter.com/mpzubairu">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path fill="currentColor" d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z"/>
              </svg>
            </a>
          </p>
        </details>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Component',
  components: {
    Aside,
    FooterView
  }
}
</script>
