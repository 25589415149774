<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
            <p>Component</p>
            <i class="fa fa-chevron-right"></i>
            <p>Form</p>
        </span>
        <h1>Form(A)</h1>
        <p>Registration Form</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>M.P Zubairu</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
            <div class="pref_div">
              <label><i class="fab fa-css3-alt"></i>Css</label>
              <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
            <div class="pref_div">
              <label><i class="fab fa-js-square"></i>Javascript</label>
              <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Form1View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
  <div class="Registration">
      <div class="form">
          <h3>Register</h3>
          <p>Please provide all requirement</p>
          <form action="post" autocomplete="off">
              <div class="txt_field">
                  <input type="text" name="FirstName" required>
                  <span></span>
                  <label for="username">First Name</label>
              </div>
              <div class="txt_field">
                  <input type="text" name="LastName" required>
                  <span></span>
                  <label for="username">Last Name</label>
              </div>
              <div class="txt_field">
                  <input type="email" name="Email" required>
                  <span></span>
                  <label for="username">Email</label>
              </div>
              <div class="txt_field password">
                  <input type="password" name="password" required>
                  <i class="fas fa-eye"></i> 
                  <span></span>
                  <label for="password">Password</label>
              </div>
              <div class="checker">
                  <input type="checkbox">
                  <span>I accept the <a href="javaScript:void(0);">Term and Condition</a></span>
              </div> 
              <input type="submit" value="Login">
              <div class="link">
                  <span>Already have an account? <a href="javaScript:void(0);">Sign In</a></span>
              </div>    
          </form>
      </div>
  </div>
</body>`
    this.$refs.textarea2.value =
`*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
:root {
  --h_color:#000;
  --p_color:#3f3c3c;
  --bg_color:#fff;
  --form_color:#fff;
  --box_shadow:rgb(202, 202, 202);
  --inner_box:#fff;
}
a{
  color: var(--h_color);
}
h1,h2,h3,h4,h5,h6{
  font-weight: 500;
  color: var(--h_color);
}
p{
  font-weight: 500;
  color: var(--p_color);
}
.fa{
  color: var(--h_color);
}
body{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.Registration{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.Registration .form{
  width: 350px;
  padding: 10px 18px;
  border-radius: 10px;
  background-color:var(--form_color);
  box-shadow: 0 0 128px 0 rgba(0,0,0,0.1),
            0 32px 64px -48px rgba(0,0,0,0.5);   
}
.Registration .form h1{
  text-align: center;
  padding: 0 0 10px 0;
  border-bottom: 1px solid silver;
}
.Registration .form form .txt_field{
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-top:10px;
  position: relative;
}
.Registration .form form .txt_field input{
  width: 100%;
  padding: .5px;
  height: 30px;
  border: none;
  outline: none;
  background-color: transparent;
}
.Registration .form form .txt_field .fas{
  position: absolute;
  right: 15px;
  top: 50%;
  color: #ccc;
  cursor: pointer;
  transform: translateY(-50%);
}
.Registration .form form .txt_field .fas.active::before{
  color: #333;
  content: "\f070";
}
.Registration .form form .txt_field label{
  position: absolute;
  top: 50%;
  left: 0px;
  color: #000;
  transform: translateY(-50%);
  font-weight: 600;
  pointer-events: none;
  transition: .5s;
  color: var(--h_color);
}
.Registration .form form .txt_field span::before{
  content: "";
  position: absolute;
  top: 30px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: #2691da;
  transition: .4s;
  transform: scale(0);
}
.Registration .form form .txt_field input:focus ~ label,
.Registration .form form .txt_field input:valid ~ label{
  top:0px;
  font-size: 10px;
  color: #2691da;
}
.Registration .form form .txt_field input:focus ~ span::before,
.Registration .form form .txt_field input:valid ~ span::before{
  width: 100%;
  transform: scale(1);
}
.Registration .form form .checker{
  margin-block: 5px;
  color: var(--h_color);
}
.Registration .form form .checker span a{
  text-decoration: none;
  color:#0090e7;
}
.Registration .form form .checker span a:hover{
  text-decoration: underline;
}
.Registration .form form input[type="submit"]{
  width: 100%;
  height: 40px;
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #2691da;
  border-radius: 5px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}
.Registration .form form input[type="submit"]:hover{
  border-color:1px solid #2691da;
  transition: .5s;
}
.Registration .form form .link{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Registration .form form .link span{
  color: var(--h_color);
}
.Registration .form form .link a{
  color: #2691da;
  cursor: pointer;
  text-decoration: none;
}
.Registration .form form a:hover{
  text-decoration: underline;
}
/* using font awesome icon */
.fa-eye:before {
  content: "\f06e";
}`
    this.$refs.textarea3.value =
`const toggleIcon = document.querySelectorAll(".form form .password i")
toggleIcon.forEach(item => {
  const pswrdField = item.previousElementSibling;
  item.addEventListener('click', () => {
      if(pswrdField.type === "password"){
          pswrdField.type = "text";
          item.classList.add("active");
      }else{
          pswrdField.type = "password";
          item.classList.remove("active");
      } 
  })
})`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
