<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Component</p>
          <i class="fa fa-chevron-right"></i>
          <p>Navigation</p>
        </span>
        <h1>Navigation Bar2</h1>
        <p>Beautiful Hover Transition Effect</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>Css Weekly (youtube)</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-css3-alt"></i>Css</label>
            <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-js-square"></i>Javascript</label>
            <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Navbar2View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
    <div class="top_nav">
        <ul>
            <li><a href="javaScript:void(0);">Home</a></li>
            <li><a href="javaScript:void(0);">Product</a></li>
            <li><a href="javaScript:void(0);">Contact</a></li>
            <li><a href="javaScript:void(0);">About</a></li>
            <li><a href="javaScript:void(0);">Service</a></li>
            <li><a href="javaScript:void(0);">Explore</a></li>
        </ul>
    </div>
    <div class="second">
        <ul>
            <li><a href="javaScript:void(0);">Home</a></li>
            <li><a href="javaScript:void(0);">Product</a></li>
            <li><a href="javaScript:void(0);">Contact</a></li>
            <li><a href="javaScript:void(0);">About</a></li>
            <li><a href="javaScript:void(0);">Service</a></li>
            <li><a href="javaScript:void(0);">Explore</a></li>
        </ul>
    </div>
    <div class="third">
        <ul>
            <li><a href="javaScript:void(0);">Home</a></li>
            <li><a href="javaScript:void(0);">Product</a></li>
            <li><a href="javaScript:void(0);">Contact</a></li>
            <li><a href="javaScript:void(0);">About</a></li>
            <li><a href="javaScript:void(0);">Service</a></li>
            <li><a href="javaScript:void(0);">Explore</a></li>
        </ul>
    </div>
</body>`
    this.$refs.textarea2.value =
`*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* object-fit: cover; */
}
.top_nav{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color:black;
}
.top_nav ul{
    display: flex;
}
.top_nav ul li{
    list-style-type: none;
    margin-inline-end: 5px;
    border-radius: 5px;
    overflow: hidden;
    /* border:1px solid black; */
}
.top_nav ul li a{
    display: block;
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    opacity: .8;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    background-color:#fff;

}
.top_nav ul li a:hover{
    opacity: 1;
}
.top_nav ul li a::before{
    content: '';
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    border-radius: 5px;
    inset-block-start: 100%;
    inset-inline-start: -100%;
    background-color: rgb(39, 81, 160);
    z-index: -1;
    transition:  all 0.3s ease-in-out;
}
.top_nav ul li a:hover::before{
    inset-block-start: 0%;
    inset-inline-start: 0%;
}
.top_nav ul li:has(+ li:hover) a::before{
    inset-block-start: 100%;
    inset-inline-start: 100%;
} 
/* add event listener */
.top_nav ul li.clicked a{
    background-color: rgb(39, 81, 160);
}
/* end of top_nav */
/* second nav */
.second{
    margin-block-start: 30px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:black;
}
.second ul{
    display: flex;
}
.second ul li{
    list-style-type: none;
    margin-inline-end: 5px;
    border-radius: 5px;
    overflow: hidden;
}
.second ul li a{
    display: block;
    position: relative;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    opacity: .8;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    background-color:#fff;

}
.second ul li a::before{
    content: '';
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    border-radius: 5px;
    inset-block-start: 0%;
    inset-inline-start: 0%;
    scale: 0;
    background-color: rgb(35, 133, 59);
    z-index: -1;
    transition:  all 0.3s ease-in-out;
}
.second ul li a:hover{
    opacity: 1;
}
.second ul li a:hover::before{
    scale: 1;
}
/* add event listener */
.second ul li.clicked a{
    background-color: rgb(35, 133, 59);
}
/* end of second */
/* third nav */
.third{
    margin-block-start: 30px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color:black;
}
.third ul{
    display: flex;
}
.third ul li{
    list-style-type: none;
    margin-inline-end: 5px;
    border-radius: 5px;
    overflow: hidden;
    border: none;
}
.third ul li a{
    display: block;
    position: relative;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    opacity: .8;
    font-weight: 600;
    z-index: 1;
    overflow: hidden;
    background-color:#fff;
}
.third ul li a::before{
    content: '';
    position: absolute;
    block-size: 100%;
    inline-size: 100%;
    border-radius: 5px;
    inset-block-start: -100%;
    inset-inline-start: -100%;
    background-color: rgb(131, 14, 14);
    z-index: -1;
    transition:  all 0.3s ease-in-out;
}
.third ul li a:hover{
    opacity: 1;
}
.third ul li a:hover::before{
    inset-block-start: 0%;
    inset-inline-start: 0%;
}
.third ul li:has(+ li:hover) a::before{
    inset-block-start: -100%;
    inset-inline-start: 100%;
}
/* add event listener */
.third ul li.clicked a{
    background-color: rgb(131, 14, 14);
}`
    this.$refs.textarea3.value =
`const tabs = document.querySelectorAll('.top_nav ul li');
function removeClick(){
    tabs.forEach((btn)=>{
        btn.classList.remove('clicked')
    })
}
tabs.forEach((btn)=>{
    btn.addEventListener('click', ()=>{
        removeClick()
        btn.classList.add('clicked')
    })
})

// second event listener
const tabs2 = document.querySelectorAll('.second ul li');

function removeClick2(){
    tabs2.forEach((btn)=>{
        btn.classList.remove('clicked')
    })
}
tabs2.forEach((btn)=>{
    btn.addEventListener('click', ()=>{
        removeClick2()
        btn.classList.add('clicked')
    })
})
// third event listener
const tabs3 = document.querySelectorAll('.third ul li');

function removeClick3(){
    tabs3.forEach((btn)=>{
        btn.classList.remove('clicked')
    })
}
tabs3.forEach((btn)=>{
    btn.addEventListener('click', ()=>{
        removeClick3()
        btn.classList.add('clicked')
    })
})`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
