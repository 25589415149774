<template>
  <div class="navigate">
    <router-link to="/" @click="Slider">Home</router-link>
    <p>Getting Started</p>
    <router-link to="/components/accordion1" @click="Slider">Componenets</router-link>
    <router-link to="/documentation" @click="Slider">Documentation</router-link>
    <router-link to="/tools" @click="Slider">Tool</router-link>
    <p>Componenets</p>
    <details>
      <summary>Accordion<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/accordion1" @click="Slider">Accordion1</router-link>
    </details>
    <details>
      <summary>Buttons<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/button1" @click="Slider">Button1</router-link>
      <router-link to="/components/button2" @click="Slider">Button2</router-link>
    </details>
    <details>
      <summary>Form<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/formA1" @click="Slider">RegistrationA</router-link>
      <router-link to="/components/formA2" @click="Slider">LoginA</router-link>
      <router-link to="/components/formB1" @click="Slider">RegistrationB</router-link>
      <router-link to="/components/formB2" @click="Slider">LoginB</router-link>
      <router-link to="/components/formC" @click="Slider">Registration & Login</router-link>
    </details>
    <details>
      <summary>Home Page<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/homepage1" @click="Slider">Home Page1</router-link>
    </details>
    <details>
      <summary>image Upload<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/upload1" @click="Slider">Upload1</router-link>
      <!-- <router-link to="/components/upload1" @click="Slider">Upload1</router-link> -->
    </details>
    <details>
      <summary>Navigations<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/navbar1" @click="Slider">Navbar1
        <!-- <div>new</div> -->
      </router-link>
      <router-link to="/components/navbar2" @click="Slider">Navbar2
        <!-- <div>new</div> -->
      </router-link>
    </details>
    <details>
      <summary>Preloader<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/preloader1" @click="Slider">Preloader1</router-link>
      <router-link to="/components/preloader2" @click="Slider">Preloader2</router-link>
    </details>
    <details>
      <summary>Progress Bar<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/progressbar1" @click="Slider">Progress Bar1</router-link>
      <router-link to="/components/progressbar2" @click="Slider">Progress Bar2</router-link>
    </details>
    <router-link to="/components/pulse" @click="Slider">Pulse Effect</router-link>
    <details>
      <summary>Slider<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/slider1" @click="Slider">Slider1</router-link>
      <router-link to="/components/slider2" @click="Slider">Slider2</router-link>
    </details>
    <details>
      <summary>Table<i class="fa fa-chevron-right"></i></summary>
      <router-link to="/components/table1" @click="Slider">Table1</router-link>
    </details>
  </div>
</template>
<script>
export default {
  name: 'AsideComponent',
  props: ['Slider']
}
</script>
