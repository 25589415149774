<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Component</p>
          <i class="fa fa-chevron-right"></i>
          <p>Button</p>
        </span>
        <h1>Button 2</h1>
        <p>Animated Delete Button</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>Web Dev Creative (youtube)</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-css3-alt"></i>Css</label>
            <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-js-square"></i>Javascript</label>
            <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Button1View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
  <div class="btn">
    <div class="back">
      <p>Are you sure you want to delete</p>
      <button class="btn_yes">Yes</button>
      <button class="btn_no">No</button>
    </div>
    <div class="front">Delete</div>
  </div>
</body>
`
    this.$refs.textarea2.value =
`*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: system-ui, Roboto, sans-serif;
}
:root{
    --red: #f44338;
    --light-red: #f77066;
    --blue: #2196f3;
    --light-blue: #51adf6;
}
body{
    width: 100%;
    min-height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    perspective: 1000px;
}
.btn{
    position: relative;
    width: 300px;
    height: 80px;
    text-align: center;
    border-radius: 10px;
    transform-origin: center;
    transform-style: preserve-3d;
    transition: width 0.3s cubic-bezier(0.23, 1, 0.320, 1),
        height 0.3s cubic-bezier(0.23, 1, 0.320, 1),
        transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.front{
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 80px;
    border-radius: 10px;
    background-color: var(--red);
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.15s ease;
    backface-visibility: hidden;
}
.front:hover{
    background-color: var(--light-red);
}
.back{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: #eee;
    color: #222;
    overflow: hidden;
    transform:translateZ(-2px) rotateX(180deg);
    /* transition: box-shadow 0.3s ease; */
}
.back p{
    margin-block: 1rem 2rem;
}
.back button{
    padding: 12px 20px;
    width: 30%;
    margin: 0 5px;
    font-size: 18px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.15s ease;
}
.btn.open{
    width: 400px;
    height: 160px;
    transform: rotateX(180deg);
}
.btn.open .back{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}
.back .btn_yes{
    background-color: var(--blue);
    color: #fff;
}
.back .btn_yes:hover{
    background-color: var(--light-blue);
}
.back .btn_no{
    color: var(--blue);
}
.back .btn_no:hover{
    background-color: #ddd;
}
.btn.open .front{
    display: none;
}`
    this.$refs.textarea3.value =
`const btn = document.querySelector('.btn')
    const front = document.querySelector('.front')
    const btn_yes = document.querySelector('.btn_yes')
    const btn_no = document.querySelector('.btn_no')
    front.addEventListener('click', ()=>{
        btn.classList.add('open')
    })
    btn_yes.addEventListener('click', ()=>{
        btn.classList.remove('open')
    })
    btn_no.addEventListener('click', ()=>{
        btn.classList.remove('open')
})`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
