<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Component</p>
          <i class="fa fa-chevron-right"></i>
          <p>Progress Bar</p>
        </span>
        <h1>Progress Bar1</h1>
        <p>Progress Bar</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>M.P Zubairu</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-css3-alt"></i>Css</label>
            <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
          <div class="pref_div">
            <label><i class="fab fa-js-square"></i>Javascript</label>
            <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Progress1View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
  <div class="progress-bar">
    <div class="bar" style="--percentage:70%; --clr:#ff2770;">
      <div class="info">
        <h1>70<small>%</small></h1>
        <h5>Percentage</h5>
      </div>
    </div>
  </div>
</body>`
    this.$refs.textarea2.value =
`*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
}
body{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress-bar{
    margin: 10px;
    width: 220px;
    height: 220px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress-bar .bar{
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: conic-gradient(from 0deg,var(--clr) 0%,
     var(--clr) 0% var(--percentage), #333 var(--percentage), #333 100%);
}
.progress-bar .bar .info{
    width: 85%;
    height: 85%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #242121;
}
.progress-bar .bar .info h1 small{
    margin:5px;
    font-size: 16px;
}
.progress-bar .bar .info h3{
    margin-top:5px;
    text-transform: uppercase;
    letter-spacing: 3px;
}`
    this.$refs.textarea3.value =
`const bar = document.querySelectorAll('.bar')
const info = document.querySelectorAll('.info h1')
for(var i=0; i<70; i++){
}
var i = 0;
setInterval(()=>{
    if(i <= 70){
        bar[0].style.setProperty('--percentage', i +'%')
        info[0].innerHTML= i + "<small>%</small>"
        i++
    }     
},50)`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
