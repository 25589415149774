<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
            <p>Component</p>
            <i class="fa fa-chevron-right"></i>
            <p>Form</p>
        </span>
        <h1>Form(B2)</h1>
        <p>Registration Form</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>M.P Zubairu</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
            <div class="pref_div">
              <label><i class="fab fa-css3-alt"></i>Css</label>
              <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
            <div class="pref_div">
              <label><i class="fab fa-js-square"></i>Javascript</label>
              <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Form1View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
  <form action="javascript:void(0);">
      <div class="logo">
      </div>
      <h3>Sign In</h3>
      <h4>Enter your email and password to sign in</h4>
      <div>
        <label for="email"> Email</label>
        <input type="email"/>
        <i class="fas fa-user"></i>
      </div>
      <div>
        <label for="password"> Password</label>
        <input type="password"/>
        <i class="fas fa-eye"></i> 
      </div>
      <input type="submit" value="Sign in">
      <div class="link">
        <a href="javascript:void(0);">Register</a>
        <a href="javascript:void(0);">Forgetton Password?</a>
      </div> 
  </form>
</body>`
    this.$refs.textarea2.value =
`{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
form{
    margin-block: auto;
    width: 350px;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 128px 0 rgba(0,0,0,0.1),
    0 32px 64px -48px rgba(0,0,0,0.5); 
}
form h3{
    font-weight: 500;
    color: #242323;
    margin-bottom: 8px;
    text-align: center;
}
form h4{
    font-weight: 500;
    color: #525151;
    text-align: center;
    font-size: 16px;
}
h4:nth-last-of-type(1){
    font-size: 14px;
}
form .logo{
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    border-radius: 100%;
    background-color:#000;
    /* background-image: url(./assets/logo.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
form .logo img{
    object-fit: contain;
}
form div:not(.link,.logo){
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
}
form div label{
    position: absolute;
    color: #525151;
    font-size: 14px;
    top: -8px;
    left: 10px;
    background-color: #fff;
}
form div input{
    height: 100%;
    padding: 6px 7px;
    padding-right: 28px;
    font-size: 16px;
    border: 1px solid #a8a8a8;
    border-radius: 5px;
    outline: none;
    color: #525151;
}
form input[type=submit]{
    margin: 10px;
    width: 100%;
    height: 40px;
    font-size: 17px;
    background-color: #000;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
}
form div i{
    position: absolute;
    top: 9px;
    right: 10px;
    color: #525151;
    font-size: 14px;
}
form .link{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
form .link span{
    color: #525151;
}
form .link a{
    color: #525151;
    cursor: pointer;
    text-decoration: none;
}
form a:hover{
    text-decoration: underline;
}`
    this.$refs.textarea3.value =
`const toggleIcon = document.querySelectorAll("form div i")
toggleIcon.forEach(item => {
  const pswrdField = item.previousElementSibling;
  item.addEventListener('click', () => {
      if(pswrdField.type === "password"){
          pswrdField.type = "text";
          item.classList.add("active");
      }else{
          pswrdField.type = "password";
          item.classList.remove("active");
      } 
  })
})`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
