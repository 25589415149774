<template>
  <header>
    <nav>
        <div class="left">
          <a class="logo" href="/">Css-Component</a>
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/documentation">Docs</router-link></li>
            <li><router-link to="/components/accordion1">Componenets</router-link></li>
            <li><router-link to="/tools">Tool</router-link></li>
          </ul>
        </div>
        <div class="right">
          <button @click="slider()">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h10"/>
            </svg>
          </button>
          <button @click="component()">Search Componenets...</button>
          <a href="https://twitter.com/mpzubairu">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path fill="currentColor" d="M13.8 10.5 20.7 2h-3l-5.3 6.5L7.7 2H1l7.8 11-7.3 9h3l5.7-7 5.1 7H22l-8.2-11.5Zm-2.4 3-1.4-2-5.6-7.9h2.3l4.5 6.3 1.4 2 6 8.5h-2.3l-4.9-7Z"/>
            </svg>
          </a>
          <input type="checkbox" id="theme-toggle" hidden ref="checkbox">
          <label for="theme-toggle" class="theme-toggle" @click="toggle()"></label>
        </div>
    </nav>
    <aside ref="sidebar">
        <div class="backdrop"></div>
        <div class="slider">
            <div class="left">
              <div class="title">
                <div>
                  <router-link class="logo" to="/"  @click="slider()">Css-Component</router-link>
                  <i class="fa fa-times"  @click="slider()"></i>
                </div>
              </div>
              <Aside :Slider= "slider"/>
            </div>
            <div class="send"  @click="slider()">
            </div>
        </div>
    </aside>
  </header>
  <router-view :key="$route.fullPath"/>
  <div ref="category" class="category" @click="component()">
    <h3>Components Available</h3>
    <ul>
      <li><router-link to="/components/accordion1">Accordion</router-link></li>
      <li><router-link to="/components/button1">Button1</router-link></li>
      <li><router-link to="/components/button2">Button2</router-link></li>
      <li><router-link to="/components/slider1">slider1</router-link></li>
      <li><router-link to="/components/slider2">slider2</router-link></li>
      <li><router-link to="/components/upload1">Upload1</router-link></li>
      <li><router-link to="/components/pulse">Pulse Effect</router-link></li>
      <li><router-link to="/components/preloader1">Preloader1</router-link></li>
      <li><router-link to="/components/preloader2">Preloader2</router-link></li>
      <li><router-link to="/components/navbar1">Navbar1</router-link></li>
      <li><router-link to="/components/navbar2">Navbar2</router-link></li>
      <li><router-link to="/components/table1">Table1</router-link></li>
      <li><router-link to="/components/formA1">RegistrationA</router-link></li>
      <li><router-link to="/components/formA2">LoginA</router-link></li>
      <li><router-link to="/components/formB1">RegistrationB</router-link></li>
      <li><router-link to="/components/formB2">LoginB</router-link></li>
      <li><router-link to="/components/formC">Registration & Login</router-link></li>
      <li><router-link to="/components/homepage1">Homepage1</router-link></li>
      <li><router-link to="/components/progressbar1">Progressbar1</router-link></li>
      <li><router-link to="/components/progressbar2">Progressbar2</router-link></li>
    </ul>
  </div>
</template>
<script>
import Aside from '@/components/Aside.vue'
export default {
  name: 'HomeView',
  components: {
    Aside
  },
  data () {
    return {
      openside: false
    }
  },
  methods: {
    slider () {
      if (window.innerWidth < 800) {
        this.$refs.sidebar.classList.toggle('sliding')
      } else {
        this.$refs.sidebar.classList.remove('sliding')
      }
    },
    component () {
      this.$refs.category.classList.toggle('show')
    },
    toggle () {
      if (this.$refs.checkbox.checked) {
        this.$el.parentNode.parentNode.classList.remove('dark')
      } else {
        this.$el.parentNode.parentNode.classList.add('dark')
      }
    }
  },
  created () {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 800) {
        this.$refs.sidebar.classList.remove('sliding')
      }
    })
  }
}
</script>
<style>
</style>
