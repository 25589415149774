<template>
  <main>
    <div class="component">
      <div class="Left">
        <Aside/>
      </div>
      <div class="Right">
        <span>
          <p>Component</p>
          <i class="fa fa-chevron-right"></i>
          <p>Upload</p>
        </span>
        <h1>Image Upload</h1>
        <p>A single Image Preview div, Click Upload.</p>
        <ul id="Preview"><li>Preview</li></ul>
        <iframe id="iframe1" frameborder="0" ref="iframe"></iframe>
        <div class="title">
          <button>Inspired By:</button>
          <p>M.P Zubairu</p>
        </div>
        <p class="description">Simple Copy and Paste.</p>
        <ul id="btn" ref="tabBtn">
          <li @click="tab(0)">Html</li>
          <li @click="tab(1)">Css</li>
          <li @click="tab(2)">Js</li>
        </ul>
        <div class="code" ref="tabs">
          <div class="pref_div">
            <label><i class="fab fa-html5"></i>Html</label>
            <textarea name="" id="textarea" readonly ref="textarea1"></textarea>
            <div class="copy">
              <h4>Status</h4>
              <i class="fa fa-copy"></i>
            </div>
          </div>
            <div class="pref_div">
              <label><i class="fab fa-css3-alt"></i>Css</label>
              <textarea name="" id="textarea" readonly ref="textarea2"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
            <div class="pref_div">
              <label><i class="fab fa-js-square"></i>Javascript</label>
              <textarea name="" id="textarea" readonly ref="textarea3"></textarea>
              <div class="copy">
                <h4>Status</h4>
                <i class="fa fa-copy"></i>
              </div>
            </div>
        </div>
      </div>
    </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Slider2View',
  components: {
    Aside,
    FooterView
  },
  date () {
    return {
      tabButton: [],
      tabView: []
    }
  },
  methods: {
    tab (id) {
      this.tabButton = Array.from(this.$refs.tabBtn.children)
      this.tabView = Array.from(this.$refs.tabs.children)
      this.tabButton.forEach((btn) => {
        btn.classList.remove('active')
      })
      this.tabButton[id].classList.add('active')
      this.tabView.forEach((tab) => {
        tab.style.display = 'none'
      })
      this.tabView[id].style.display = 'block'
    }
  },
  mounted () {
    this.tab(0)
    const copytab = this.tabView
    copytab.forEach((copycontainer) => {
      const copytextarea = copycontainer.querySelector('#textarea')
      const copybutton = copycontainer.querySelector('.copy .fa-copy')
      const status = copycontainer.querySelector('h4')
      copytextarea.addEventListener('focus', () => {
        copytextarea.select()
      })
      copybutton.addEventListener('click', () => {
        const Text = copytextarea.value
        copytextarea.select()
        navigator.clipboard.writeText(Text)
        status.textContent = 'copied to clipboard'
        copybutton.style.color = '#46c35f'
      })
    })
    this.$refs.textarea1.value =
`<body>
<div class="image-div">
  <div class="image-placeholder"></div>
    <input type="file" name="" id="fileinput">
    <label for="fileinput">
      <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <path fill-rule="evenodd" d="M12 3c.3 0 .6.1.8.4l4 5a1 1 0 1 1-1.6 1.2L13 7v7a1 1 0 1 1-2 0V6.9L8.8 9.6a1 1 0 1 1-1.6-1.2l4-5c.2-.3.5-.4.8-.4ZM9 14v-1H5a2 2 0 0 0-2 2v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4v1a3 3 0 1 1-6 0Zm8 2a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clip-rule="evenodd"/>
      </svg>
    </label>
  </div>
</body>`
    this.$refs.textarea2.value =
`*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* or contain */
    /* object-fit: contain; */
}
body{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .image-div{
    width: 150px;
    aspect-ratio: 1/1;
    border-radius: 5px;
    position: relative;
    overflow:hidden;
 }
.image-placeholder{
    background-color:rgba(179,179,179,1);
    width: 100%;
    height: 100%;
}
input[type="file"]{
    display: none;
}
label{
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 3px;
    border-radius: 5px;
    background-color: rgb(96, 96, 245);
}
svg{
   fill: white;
   width: 25px;
   height: 25px;
}`
    this.$refs.textarea3.value =
`const upload = document.querySelector('input');
const preview = document.querySelector('.image-placeholder');
upload.addEventListener('change', ()=> {
    const file = upload.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', ()=>{
        //console.log(reader.result)
        source = reader.result

        const img = document.createElement('img');
        img.setAttribute('src', source)
        //console.log(img)
        //Avatar.innerHTML = img;
        preview.innerHTML="";
        preview.appendChild(img)
    })
    reader.readAsDataURL(file);
})`
    this.$refs.iframe.contentDocument.body.innerHTML = `${this.$refs.textarea1.value}` + '<style>' + `${this.$refs.textarea2.value}` + 'style>'
    this.$refs.iframe.contentWindow.eval(this.$refs.textarea3.value)
  }
}
</script>
