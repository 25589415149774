<template>
  <main>
      <div class="component">
        <div class="Left">
          <Aside/>
        </div>
        <div class="Right">
          <span>
            <p>Doc</p>
            <i class="fa fa-chevron-right"></i>
            <p>Tools</p>
          </span>
          <h1>Fantastic CSS Generators</h1>
          <p class="P">Beautifully designed components that you can copy and paste
            into your apps. Accessible. Customizable.
          </p>
          <div class="tools">
            <div class="grid">
              <div class="grid_inner">
                <div class="generators">
                  <h4>Color Gradient </h4>
                  <p>By Joshua Comeau</p>
                  <a href="https://www.joshwcomeau.com/gradient-generator/">https://www.joshwcomeau.com/gradient-generator/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Shadow Palette Generator</h4>
                  <p>By Joshua Comeau</p>
                  <a href="https://www.joshwcomeau.com/shadow-palette/">https://www.joshwcomeau.com/shadow-palette/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Fancy Border Radius</h4>
                  <p>By Mirko $ Nils/9Elements</p>
                  <a href="https://9elements.github.io/fancy-border-radius/">https://9elements.github.io/fancy-border-radius/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Get Waves</h4>
                  <p>By Creative Lab</p>
                  <a href="https://getwaves.io/">https://getwaves.io/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>FFFuel</h4>
                  <p>By Sebastian Noel</p>
                  <a href="https://fffuel.co/">https://fffuel.co/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>CSS Generators</h4>
                  <p>By Temeni Afifsss</p>
                  <a href=" https://css-generators.com/">https://css-generators.com/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Scrollar App</h4>
                  <p>By Henri Parvianen</p>
                  <a href="https://scrollbar.app/">https://scrollbar.app/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Glassmorphism</h4>
                  <p>By Flow Bite</p>
                  <a href="https://ui.glass/generator/">https://ui.glass/generator/</a>
                </div>
              </div>
              <div class="grid_inner">
                <div class="generators">
                  <h4>Mesh CSS Hero</h4>
                  <p></p>
                  <a href=" https://csshero.org/mesher/">https://csshero.org/mesher/</a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </main>
  <FooterView/>
</template>
<script>
import Aside from '@/components/Aside.vue'
import FooterView from '@/components/FooterView.vue'
export default {
  name: 'Component',
  components: {
    Aside,
    FooterView
  }
}
</script>
