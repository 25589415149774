<template>
    <footer>
        <p>Build By MpZubairu.ng</p>
    </footer>
</template>
<script>
export default {
  name: 'FooterView'
}
</script>
