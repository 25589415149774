<template>
  <main>
    <div class="container"></div>
    <div class="home">
      <button><a href="https://www.buymeacoffee.com/mpzubairu" target="Buy me a coffee"><i class="fa fa-coffee" ></i>&nbsp;&nbsp;Buy Me A Coffee</a></button>
      <h1>Sourced css component Library</h1>
      <p>Beautifully designed web components that you can copy and paste
        into your apps. Accessible. Customizable.</p>
      <span>
        <router-link to="/documentation">Get started</router-link>
        <router-link to="/components/accordion1">Components</router-link>
      </span>
    </div>
  </main>
</template>
<script>
export default {
  name: 'HomeView'
}
</script>
